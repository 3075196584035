<template>
    <v-btn 
        color="red" 
        dark 
        block 
        :disabled="disabled"
        :loading="loading"
        @click="login"
    ><slot></slot></v-btn>
</template>

<script>
    import * as msal from '@azure/msal-browser';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                instance: null,
            }
        },
        mounted() {
            const config = {
                auth: {
                    clientId: process.env.VUE_APP_MICROSOFT_GRAPH_CLIENT_ID
                }
            };

            this.instance = new msal.PublicClientApplication(config);
        },
        methods: {
            async login() {
                try {
                    const loginResponse = await this.instance.loginPopup({});
                    this.$emit('success', loginResponse);
                } catch (err) {
                    this.$root.$snackbar.open({
                        text: err,
                        type: 'error',
                    })
                }
            }
        }
    }
</script>
